// Bootstrap 5 header functionality

import $ from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

document.addEventListener('DOMContentLoaded', function () {
  const newsDesktopDropdown = document.getElementById('news-desktop-dropdown');
  const jobsDesktopDropdown = document.getElementById('jobs-desktop-dropdown');
  const fundingDesktopDropdown = document.getElementById('funding-desktop-dropdown');
  const talentDesktopDropdown = document.getElementById('talent-desktop-dropdown');
  const eventsDesktopDropdown = document.getElementById('events-desktop-dropdown');
  const whatWeDoDropdown = document.getElementById('what-we-do-dropdown');
  const mobileMenu = document.getElementById('mobile-menu');
  const mobileMenuBSCollapse = new bootstrap.Collapse(mobileMenu, { toggle: false });
  // Search bar
  const searchToggle = document.getElementById('search-toggle');
  const searchContainer = document.getElementById('search-container');
  const searchInput = document.getElementById('search-input');
  const searchButton = document.getElementById('search-button');
  const clearSearch = document.getElementById('clear-search');
  const headerNavItems = document.querySelectorAll('.desktop-navbar-dropdowns-nav-item');

  // B5 Toggle the search container, hide the other nav items and focus on the search input
  searchToggle.addEventListener('click', function(event) {
    event.preventDefault();
    searchContainer.classList.remove('d-none');
    searchToggle.classList.add('d-none');
    headerNavItems.forEach(item => {
      item.classList.toggle('d-none');
    });
    if (!searchContainer.classList.contains('d-none')) {
      searchInput.focus();
    }
  });

  // B5 Hide search container and show nav items when clicking outside
  document.addEventListener('click', function(event) {
    const isClickInside =
      searchContainer.contains(event.target) || searchToggle.contains(event.target);
    if (!isClickInside) {
      searchContainer.classList.add('d-none');
      searchToggle.classList.remove('d-none');
      headerNavItems.forEach(item => {
        item.classList.remove('d-none');
      });
    }
  });

  // B5 Disable the search and hide the clear button until the user types something
  searchInput.addEventListener('input', () => {
    if (searchInput.value.trim().length > 0) {
      searchButton?.removeAttribute('disabled');
      searchButton?.removeAttribute('aria-disabled');
      searchButton?.classList.remove('disabled');
      clearSearch?.classList.remove('d-none');
    } else {
      searchButton?.setAttribute('disabled', 'disabled');
      searchButton?.setAttribute('aria-disabled', 'true');
      searchButton?.classList.add('disabled');
      clearSearch?.classList.add('d-none');
    }
  });

  // B5 Close the search container when pressing the escape key
  document.addEventListener('keyup', function(event) {
    if (event.key === 'Escape') {
      searchContainer.classList.add('d-none');
      searchToggle.classList.remove('d-none');
      headerNavItems.forEach(item => {
        item.classList.remove('d-none');
      });
    }
  });

  // B5 Search for the input value when pressing enter
  searchInput.addEventListener('keyup', function(event) {
    const trimmedValue = searchInput.value.trim();
    if (event.key === 'Enter' && trimmedValue.length > 0) {
      window.location.href = `/search?q=${encodeURIComponent(trimmedValue)}`;
    }
  });

  // B5 Search for the input value when clicking the search button
  searchButton.addEventListener('click', function(event) {
    const trimmedValue = searchInput.value.trim();
    event.preventDefault();
    window.location.href = `/search?q=${encodeURIComponent(trimmedValue)}`;
  });

  // B5 Clear the search input, hide the clear button, disable the search button and focus on the
  // input
  clearSearch.addEventListener('click', function(event) {
    event.preventDefault();
    searchInput.value = '';
    clearSearch?.classList.add('d-none');
    searchButton?.setAttribute('disabled', 'disabled');
    searchButton?.setAttribute('aria-disabled', 'true');
    searchButton?.classList.add('disabled');
    searchInput.focus();
  });

  /**
   * Enable all the Bootstrap Dropdown with Javascript.
   */
  const enableBSDropdowns = () => {
    const newsDesktopDropdownInstance = new bootstrap.Dropdown(newsDesktopDropdown);

    newsDesktopDropdown.addEventListener('click', () => {
      newsDesktopDropdownInstance.toggle();
    });

    const jobsDesktopDropdownInstance = new bootstrap.Dropdown(jobsDesktopDropdown);

    jobsDesktopDropdown.addEventListener('click', () => {
      jobsDesktopDropdownInstance.toggle();
    });

    const fundingDesktopDropdownInstance = new bootstrap.Dropdown(fundingDesktopDropdown);

    fundingDesktopDropdown.addEventListener('click', () => {
      fundingDesktopDropdownInstance.toggle();
    });

    const talentDesktopDropdownInstance = new bootstrap.Dropdown(talentDesktopDropdown);

    talentDesktopDropdown.addEventListener('click', () => {
      talentDesktopDropdownInstance.toggle();
    });

    const eventsDesktopDropdownInstance = new bootstrap.Dropdown(eventsDesktopDropdown);

    eventsDesktopDropdown.addEventListener('click', () => {
      eventsDesktopDropdownInstance.toggle();
    });

    const whatWeDoDropdownInstance = new bootstrap.Dropdown(whatWeDoDropdown);

    whatWeDoDropdown.addEventListener('click', () => {
      whatWeDoDropdownInstance.toggle();
    });
  };

  /**
   * Enable all the Bootstrap Collapse with Javascript.
   * We need to do it with JS in order to be able to close the collapse
   * elements when clicking outside (listenToOutsideClicks function).
   */
  const enableBSCollapse = () => {
    const moreMenuMobileButton = document.getElementById('mainNavMobileDropdown');

    mobileMenu.addEventListener('show.bs.collapse', () => {
      moreMenuMobileButton.classList.remove('collapsed');
    });

    mobileMenu.addEventListener('hide.bs.collapse', () => {
      moreMenuMobileButton.classList.add('collapsed');
    });

    moreMenuMobileButton.addEventListener('click', () => {
      mobileMenuBSCollapse.toggle();
    });
  };

  /**
   * Listens to all the clicks on the document and determines whether or not the click should also
   * hide either expanded Navigation More menu or expanded User main menu.
   *
   * @returns {undefined}
   */
  var listenToOutsideClicks = function () {
    $(document).on('click', function (event) {
      var $clickTarget = $(event.target);
      var $menus = $('#mobile-menu');

      if (
        $menus.hasClass('show') &&
        !$clickTarget.hasClass('navbar-toggle') &&
        !$clickTarget.parents('#mobile-menu').length
      ) {
        mobileMenuBSCollapse.hide();
      }
    });
  };

  $('#user-menu-logout').on('click', function () {
    // Prevent user for being auto-logged-in again after logging out
    var currentDate = new Date();
    localStorage.setItem('preventAutoLogin', currentDate.getTime().toString());
    if (window.PasswordCredential) {
      navigator.credentials.preventSilentAccess();
    }
  });

  enableBSCollapse();
  enableBSDropdowns();
  listenToOutsideClicks();
});
